import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Link, Theme } from '@mui/material';
import { Table } from 'src/components/common/Table';
import { routesMap } from 'src/routes';
import { Contractor } from 'src/shared/api';

interface Props {
  contractors?: Contractor[] | null;
  totalCount?: number | null;
  onFetch: () => any;
  onChange: (value: any) => any;
  limit?: number | null;
  offset?: number | null;
  loading: boolean;
  contractorActions?: (contractor: Contractor) => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'Contractors' },
);

export const Contractors: React.FunctionComponent<Props> = ({
  contractors = [],
  totalCount = 0,
  onFetch,
  limit = 0,
  offset = 0,
  onChange,
  loading,
  contractorActions,
}) => {
  const classes = useStyles({});

  return (
    <Table
      title="Контрагенты"
      headers={[
        {
          title: 'id',
          component: 'th',
          scope: 'row',
          align: 'right',
          extract: (item: Contractor) =>
            item.id && (
              <Link
                href={routesMap.contractor.getRoute(item.id)}
                target="_blank"
              >
                {item.id}
              </Link>
            ),
        },
        {
          title: 'Название',
          extract: (item: Contractor) => item.name,
        },
        {
          title: 'ИНН',
          align: 'right',
          extract: (item: Contractor) => item.inn,
        },
        {
          title: 'vendorId',
          align: 'right',
          extract: (item: Contractor) => item.vendorId,
        },
        {
          title: 'Пользователи',
          extract: (item: Contractor) => item.users?.length,
        },
        {
          title: 'Количество торговых точек',
          align: 'right',
          extract: (item: Contractor) => item.outlets?.length,
        },
        {
          title: 'Действия',
          align: 'right',
          extract: (item: Contractor) => (
            <>{contractorActions && contractorActions(item)}</>
          ),
        },
      ]}
      data={contractors}
      totalCount={totalCount}
      onFetch={onFetch}
      onChange={onChange}
      loading={loading}
      limit={limit}
      offset={offset}
      withPagination
    />
  );
};
