import { useGetContractors as depricated_useGetContractors } from 'src/services/apiService';
import { request } from 'src/shared/api';
import { apiRoutesMap } from 'src/shared/config';

export const useGetContractors = depricated_useGetContractors;

export const deleteUserFromContractor = (
  contractorId: string | number,
  userId: string | number,
) =>
  request.delete<any>(
    apiRoutesMap.contractorUser.getRoute(contractorId, userId),
  );
