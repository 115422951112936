import { useEffect, useState } from 'react';
import { Address, User } from 'src/shared/api';
import { Api, Promotion, ZakazLog } from 'src/types/api';
import useSWR, { mutate } from 'swr';

export const apiService = new Api({
  baseUrl: process.env.REACT_APP_API_HOST,
  baseApiParams: { credentials: 'include' },
});

type RequestHookParams<T> = { variables?: T; skip?: boolean };

export function useGetContractor({
  contractorId,
}: {
  contractorId?: string | null;
  query?: string | string[] | null;
}) {
  const { data, error, mutate } = useSWR(
    contractorId
      ? [apiService.v1Api.contractorsDetail.name, contractorId]
      : null,
    () =>
      contractorId
        ? apiService.v1Api.contractorsDetail(contractorId).then(res => res.data)
        : null,
    { revalidateOnFocus: false },
  );

  return {
    data,
    isLoading: !error && !data,
    error,
    mutate,
  };
}

export const useGetContractors = ({
  variables,
  skip,
}: RequestHookParams<{
  limit?: string;
  offset?: string;
  query?: string;
}>) =>
  useSWR(
    skip
      ? null
      : [
          apiService.v1Api.contractorsList.name,
          variables?.limit,
          variables?.offset,
          variables?.query,
        ],
    () => apiService.v1Api.contractorsList(variables).then(res => res.data),
    { revalidateOnFocus: false },
  );

export function useGetUsers({
  limit,
  offset,
  query,
  skip,
}: {
  limit?: string;
  query?: string;
  offset?: string;
  skip?: boolean;
}) {
  const { data, error, isValidating } = useSWR(
    skip ? null : [apiService.v1Api.usersList.name, limit, offset, query],
    () =>
      apiService.v1Api
        .usersList({ limit, offset, query })
        .then(res => res.data),
    { revalidateOnFocus: false },
  );

  return {
    data,
    isLoading: isValidating,
    error,
  };
}

export function useGetUserMe() {
  const { data, error, isValidating } = useSWR(
    apiService.v1Api.usersMeList.name,
    () => apiService.v1Api.usersMeList().then(res => res.data),
    { revalidateOnFocus: false },
  );

  return {
    data,
    isLoading: isValidating,
    error,
  };
}

export function useContractorsUsersCreate({ id }: { id: string }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  return [
    (params: { id?: string; user: User }) =>
      apiService.v1Api
        .contractorsUsersCreate(params.id || id, params.user)
        .then(res => {
          setIsLoading(false);

          setData(res.data);

          return res.data;
        }),
    {
      data,
      isLoading,
    },
  ];
}

export function useGetWarehouse({
  warehouseId,
}: {
  warehouseId?: string | number | null;
}) {
  const { data, error } = useSWR(
    warehouseId ? [apiService.v1Api.contractorsDetail.name, warehouseId] : null,
    () =>
      warehouseId
        ? apiService.v1Api
            .warehousesDetail(warehouseId.toString())
            .then(res => res.data)
        : null,
    { revalidateOnFocus: false },
  );

  const refetch = () =>
    mutate([apiService.v1Api.contractorsDetail.name, warehouseId]);

  return {
    data,
    isLoading: !error && !data,
    error,
    refetch,
  };
}

export function useUpdateWarehouseAddress({
  warehouseId,
}: {
  warehouseId: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  return [
    (params: { id: string; data: Address }) =>
      apiService.v1Api
        .warehousesAddressUpdate(params.id, params.data)
        .then(res => {
          setIsLoading(false);

          setData(res.data);

          return res.data;
        }),
    {
      data,
      isLoading,
    },
  ];
}

export function useCreateUser() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<User | null>(null);

  return {
    execute: (params: { user: User }) =>
      apiService.v1ApiPrivate.usersCreate(params.user).then(res => {
        setIsLoading(false);

        setData(res.data);

        return res.data;
      }),

    data,
    isLoading,
  };
}

export function useGetOrders({
  variables,
  skip,
}: RequestHookParams<{
  limit?: number | string;
  offset?: number | string;
  outlets?: string | null;
  users?: string | number | null;
  from?: string | number | null;
  to?: string | number | null;
  byUnit?: string | number | null;
  state?: 'processed' | 'draft' | 'review';
}>) {
  const key = [
    apiService.v1Api.ordersList.name,
    variables?.limit,
    variables?.offset,
    variables?.outlets,
    variables?.users,
    variables?.state,
    variables?.from,
    variables?.to,
    variables?.byUnit,
  ];

  const { data, error, isValidating } = useSWR(
    skip ? null : key,
    () => apiService.v1Api.ordersList(variables).then(res => res.data),
    { revalidateOnFocus: false },
  );

  const refetch = () => mutate(key);

  return {
    data,
    isLoading: isValidating,
    error,
    refetch,
  };
}

export function useProducts({
  variables,
  skip,
}: RequestHookParams<{
  limit?: number | string | null;
  offset?: number | string | null;
  manufacturers?: number | string | null;
  brands?: number | string | null;
  query?: string | null;
  codes?: string | number | null;
}>) {
  const key = [
    apiService.v1Api.productsList.name,
    variables?.limit,
    variables?.offset,
    variables?.manufacturers,
    variables?.brands,
    variables?.query,
    variables?.codes,
  ];

  const { data, error, isValidating } = useSWR(
    skip ? null : key,
    () => apiService.v1Api.productsList(variables).then(res => res.data),
    { revalidateOnFocus: false },
  );

  const refetch = () => mutate(key);

  return {
    data,
    isLoading: isValidating,
    error,
    refetch,
  };
}

export function useGetOrder({
  variables,
  skip,
}: RequestHookParams<{ id: number | string }>) {
  const key = [apiService.v1Api.ordersDetail.name, variables?.id];

  const { data, error, isLoading } = useSWR(
    skip ? null : key,
    () =>
      apiService.v1Api
        .ordersDetail(variables?.id as number)
        .then(res => res.data),
    { revalidateOnFocus: false },
  );

  const refetch = () => mutate(key);

  return {
    data,
    isLoading,
    error,
    refetch,
  };
}

export function useGetPromotions({
  variables,
  skip,
}: RequestHookParams<{
  limit?: string;
  offset?: string;
  outlets?: string;
  vendorId?: string;
}>) {
  const key = [
    apiService.v1ApiPrivate.promotionsList.name,
    variables?.limit,
    variables?.offset,
    variables?.outlets,
    variables?.vendorId,
  ];

  const { data, error, isValidating } = useSWR(
    skip ? null : key,
    () =>
      apiService.v1ApiPrivate.promotionsList(variables).then(res => res.data),
    { revalidateOnFocus: false },
  );

  const refetch = () => mutate(key);

  return {
    data,
    isLoading: isValidating,
    error,
    refetch,
  };
}

export function useGetPromotionInitiators({
  variables,
  skip,
}: RequestHookParams<{ limit?: string; offset?: string }>) {
  const key = [
    apiService.v1ApiPrivate.promotionInitiatorsList.name,
    variables?.limit,
    variables?.offset,
  ];

  const { data, error, isValidating } = useSWR(
    skip ? null : key,
    () =>
      apiService.v1ApiPrivate
        .promotionInitiatorsList(variables)
        .then(res => res.data),
    { revalidateOnFocus: false },
  );

  const refetch = () => mutate(key);

  return {
    data,
    isLoading: isValidating,
    error,
    refetch,
  };
}

export function useGetPromotion({
  promotionId,
}: {
  promotionId?: string | null;
  query?: string | string[] | null;
}) {
  const key = [apiService.v1ApiPrivate.promotionsDetail.name, promotionId];
  const { data, error } = useSWR(
    promotionId ? key : null,
    () =>
      promotionId
        ? apiService.v1ApiPrivate
            .promotionsDetail(promotionId)
            .then(res => res.data)
        : null,
    { revalidateOnFocus: false },
  );

  const refetch = () => mutate(key);

  return {
    data,
    isLoading: !error && !data,
    error,
    refetch,
  };
}

export function useGetPromotionInitiator({
  promotionInitiatorId,
}: {
  promotionInitiatorId?: string | null;
  query?: string | string[] | null;
}) {
  const key = [
    apiService.v1ApiPrivate.promotionsInitiatorDetail.name,
    promotionInitiatorId,
  ];
  const { data, error } = useSWR(
    promotionInitiatorId ? key : null,
    () =>
      promotionInitiatorId
        ? apiService.v1ApiPrivate
            .promotionsInitiatorDetail(promotionInitiatorId)
            .then(res => res.data)
        : null,
    { revalidateOnFocus: false },
  );

  const refetch = () => mutate(key);

  return {
    data,
    isLoading: !error && !data,
    error,
    refetch,
  };
}

export function useUpdatePromotion() {
  return [
    (params: { id: string | number; data: Partial<Promotion> }) =>
      apiService.v1ApiPrivate
        .promotionsUpdate(params.id, params.data)
        .then(res => res.data),
  ];
}

export function useUpdatePromotionInitiator() {
  return [
    (params: { id: string | number; data: Partial<Promotion> }) =>
      apiService.v1ApiPrivate
        .promotionsInitiatorsUpdate(params.id, params.data)
        .then(res => res.data),
  ];
}

export function useGetUser(params: RequestHookParams<{ id: number | string }>) {
  const { data, error } = useSWR(
    params.variables?.id
      ? [apiService.v1Api.contractorsDetail.name, params.variables.id]
      : null,
    () =>
      params.variables?.id
        ? apiService.v1ApiPrivate
            .user(params.variables.id)
            .then(res => res.data)
        : null,
    { revalidateOnFocus: false },
  );

  const refetch = () =>
    mutate([apiService.v1Api.contractorsDetail.name, params.variables?.id]);

  return {
    data,
    isLoading: !error && !data,
    error,
    refetch,
  };
}

export function useGetOrderLogs(
  params: RequestHookParams<{ id: number | string }>,
) {
  const data = useSWR(
    params.variables?.id
      ? [apiService.v1ApiPrivate.orderLogs.name, params.variables?.id]
      : null,
    () =>
      params.variables?.id
        ? apiService.v1ApiPrivate
            .orderLogs(params.variables.id)
            .then(res => res.data)
        : null,
    { revalidateOnFocus: false },
  );

  return data;
}

export function useGetZakazLogs(
  params: RequestHookParams<{ id: number | string }>,
) {
  const data = useSWR(
    params.variables?.id
      ? [apiService.v1ApiPrivate.zakazLogs.name, params.variables.id]
      : null,
    () =>
      params.variables?.id
        ? apiService.v1ApiPrivate
            .zakazLogs(params.variables.id)
            .then(res => res.data)
        : null,
    { revalidateOnFocus: false },
  );

  return data;
}

export function useGetZakazesLogs(
  params: RequestHookParams<{ guids?: string }>,
) {
  const [data, setData] = useState<null | {
    [key: string]: ZakazLog[] | null;
  }>(null);

  useEffect(() => {
    if (!params.skip) {
      params.variables?.guids?.split(',')?.forEach(guid =>
        apiService.v1ApiPrivate.zakazLogs(guid).then(res => {
          if (!res.data?.data?.length) return null;
          data
            ? setData({ ...data, [guid]: res.data?.data })
            : setData({ [guid]: res.data?.data });
        }),
      );
    }
  }, [params.variables?.guids, params.skip]);

  return { data };
}
