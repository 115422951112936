import { useMemo } from 'react';
import { useGetAttributes, useGetTypes } from 'src/entities/category';
import { Category } from 'src/shared/api';

const parseQuery = (query: string) => {
  const queryObject = new URLSearchParams(query);

  return Array.from(queryObject)?.reduce(
    (prev, curr) => ({
      ...prev,

      [curr[0]]: [
        //@ts-ignore
        ...(prev?.[curr[0]] || []),
        //@ts-ignore
        ...(curr[1]?.split(',') || []),
      ],
    }),
    {},
  );
};


export const categoryTreeToFlat = (categories: Category[]): Category[] =>
  //@ts-ignore
  categories.reduce(
    //@ts-ignore
    (prev, curr) => [
      ...prev,
      ...(curr.children?.length
        ? [
            { ...curr, parsedQuery: curr.query && parseQuery(curr.query) },
            ...categoryTreeToFlat(curr.children),
          ]
        : [{ ...curr, parsedQuery: curr.query && parseQuery(curr.query) }]),
    ],
    [],
  );

export const useQueryToFilters = ({
  query,
  skip = false,
}: {
  query?: string | null;
  skip?: boolean;
}) => {
  const parsedQuery = useMemo(
    () => (query && !skip ? parseQuery(query) : null),
    [query, skip],
  );

  const typesRequest = useGetTypes({ skip, variables: {} });

  const typesMap = useMemo(
    () =>
      typesRequest.data?.data.reduce(
        (prev, curr) => ({ ...prev, [curr.value]: curr }),
        {},
      ),
    [typesRequest.data],
  );

  const queryTypes = useMemo(
    () =>
      //@ts-ignore
      parsedQuery?.types?.map(
        (typeId: string) =>
          //@ts-ignore
          typesMap?.[typeId] || {
            name: `Неизвестный тип ${typeId}`,
            value: typeId,
          },
      ),
    [typesMap, parsedQuery],
  );

  const attributesRequest = useGetAttributes({
    variables: { query },
    skip,
  });

  const queryAttributes = useMemo(
    () =>
      //@ts-ignore
      parsedQuery &&
      Object.entries(parsedQuery)
        //@ts-ignore
        .filter(item => item[0].includes('attr['))
        .map((item: any) => {
          const findedAttribute = attributesRequest.data?.data.find(
            attribute => attribute.key === item[0],
          );

          const attribute = findedAttribute
            ? {
                ...findedAttribute,
                values: findedAttribute.values.filter((value: any) =>
                  item[1].includes(String(value.value)),
                ),
              }
            : {
                title: `Неизвестный аттрибут ${item[0]}`,
                key: item[0],
                values: item[1],
              };

          return attribute;
        }),
    [attributesRequest.data, parsedQuery],
  );

  return {
    parsedQuery,
    types: typesRequest.data?.data,
    attributes: attributesRequest.data?.data,
    typesMap,
    queryTypes,
    queryAttributes,
  };
};
