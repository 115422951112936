import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Link, Paper, Theme } from '@mui/material';
import { format } from 'date-fns';
import { Table } from 'src/components/common/Table';
import { useRouter } from 'src/hooks/useRouter';
import { routesMap } from 'src/routes';
import { User } from 'src/shared/api';

interface Props {
  loading: boolean;
  data?: User[] | null;
  limit?: number | null;
  offset?: number | null;
  totalCount?: number | null;
  onFetch?: () => any;
  onChange?: (params: { page: number; limit: number }) => any;
  actions?: [(params: { selectedItems: any }) => React.ReactNode];
  itemActions?: (user: User) => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'Users' },
);

export const Users: React.FunctionComponent<Props> = ({
  data,
  totalCount,
  loading,
  actions = [],
  itemActions,
  onChange,
  onFetch,
  limit,
  offset,
}) => {
  const classes = useStyles({});

  const { push } = useRouter();

  return (
    <div className={classes.root}>
      <Paper>
        <Table
          title="Пользователи"
          headers={[
            {
              title: 'id',
              component: 'th',
              scope: 'row',
              align: 'right',
              extract: (item: User) =>
                item.id && (
                  <Link href={routesMap.user.getRoute(item.id)} target="_blank">
                    {item.id}
                  </Link>
                ),
            },
            {
              title: 'Дата создания',
              extract: (item: User) =>
                item.createdAt &&
                format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm:ss'),
            },
            {
              title: 'vendorId',
              extract: (item: User) => item.vendorId,
            },
            {
              title: 'Email',
              extract: (item: User) => item.email,
            },
            {
              title: 'Телефон',
              extract: (item: User) => (
                <Link href={`tel:+7${item.phoneNumber}`}>
                  +7{item.phoneNumber}
                </Link>
              ),
            },
            {
              title: 'ФИО',
              extract: (item: User) =>
                [item?.lastName, item?.firstName, item?.fatherName]
                  ?.filter(item => item)
                  ?.join(' '),
            },
            {
              title: 'Контрагенты',
              extract: (item: User) => (
                <Box display="flex">
                  {item.contractors?.map(
                    contractor =>
                      contractor?.id && (
                        <Box mr={1}>
                          <Link
                            href={routesMap.contractor.getRoute(contractor?.id)}
                            target="_blank"
                          >
                            {contractor.name}
                          </Link>
                        </Box>
                      ),
                  )}
                </Box>
              ),
            },
            {
              title: 'Роли',
              extract: (item: User) => item?.roles?.join(', '),
            },
            {
              title: 'Действия',
              align: 'right',
              extract: itemActions,
            },
          ]}
          data={data}
          totalCount={totalCount}
          onFetch={onFetch}
          onChange={onChange}
          loading={loading}
          actions={actions}
          limit={limit}
          offset={offset}
        />
      </Paper>
    </div>
  );
};
