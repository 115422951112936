import React, { useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Typography,
} from '@mui/material';

import {
  categoryTreeToFlat,
  useGetTypes,
  useQueryToFilters,
} from 'src/entities/category';
import { Category } from 'src/shared/api';

export const TypesWithoutCategories: React.FunctionComponent<{
  categories?: Category[] | null;
}> = ({ categories }) => {
  const typesRequest = useGetTypes({
    skip: !categories,
    variables: { instock: true },
  });

  const typesInCategories = useMemo(
    () =>
      categories
        ? (categoryTreeToFlat(categories)?.reduce(
            //@ts-ignore
            (prev, curr) => [...prev, ...(curr.parsedQuery?.types || [])],
            [],
          ) as string[])
        : null,
    [categories],
  );

  const missedTypes =
    typesRequest.data?.data &&
    categories &&
    typesRequest.data?.data
      ?.filter(type => !typesInCategories?.includes(String(type.value)))
      ?.sort((a, b) => (a.count > b.count ? -1 : 1));

  return (
    <Accordion>
      <AccordionSummary>
        <Typography variant="h5">
          Типы без категорий ({missedTypes?.length || 0})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box style={{ columns: 6 }}>
          {missedTypes?.map(type => (
            <div key={type.value}>
              <Link
                target="_blank"
                href={`${process.env.REACT_APP_PUBLIC_PORTAL_HOST}/products?types=${type.value}`}
              >
                {type.name} ({type.count})
              </Link>
            </div>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
