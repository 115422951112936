import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  Theme,
  Button,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Fab,
  DialogTitle,
  Paper,
  Link,
  Chip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  useGetAttributes,
  useGetCategories,
  useGetTypes,
  useQueryToFilters,
} from 'src/entities/category';
import { changeCategory, createCategory } from 'src/entities/category';
import {
  Add,
  CheckBox,
  Close,
  Edit,
  TypeSpecimenTwoTone,
} from '@mui/icons-material';
import { Category } from 'src/shared/api';
import { routesMap } from 'src/routes';
import { removeEmptyProps } from 'src/helpers/removeEmtyProperties';
import { categoriesRefresh, useGetCategory } from 'src/entities/category/api';
import { TypesWithoutCategories } from './TypesWithoutCategories';
import fuzzy from 'fuzzy';
import { WithLoading } from 'src/components/common/WithLoading';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'CategoriesPage' },
);

const CategoryFilterType: React.FunctionComponent<{
  type: Type;
  onDelete: any;
}> = ({ type, onDelete }) => {
  return (
    <div key={type?.value}>
      <Chip
        label={
          <Link
            target="_blank"
            href={`${process.env.REACT_APP_PUBLIC_PORTAL_HOST}/products?types=${type?.value}`}
          >
            {type.name}
          </Link>
        }
        variant="outlined"
        onDelete={() => onDelete('types', type?.value)}
      />
    </div>
  );
};

const CategoryFilterAttribute: React.FunctionComponent<{
  attribute: any;
  onDelete: any;
}> = ({ attribute, onDelete }) => {
  return (
    <div>
      {attribute.title}

      {attribute.values.map((value: any) => (
        <div key={value?.value}>
          <Chip
            onDelete={() => onDelete(attribute.key, value?.value)}
            variant="outlined"
            label={
              <Link
                target="_blank"
                href={`${process.env.REACT_APP_PUBLIC_PORTAL_HOST}/products?${attribute.key}=${value?.value}`}
              >
                {value.name}
              </Link>
            }
          />
        </div>
      ))}
    </div>
  );
};

const fuzzyOptions = {
  extract: (el: any) => el.name,
  returnAllOnEmptyString: true,
  returnOriginal: true,
};

const fuzzyFilter = (string: string, data: any, options: any) => {
  if (options?.returnAllOnEmptyString && !string) return data;
  const result = fuzzy.filter(string, data, options);
  if (options?.returnOriginal) {
    return result.map(item => item.original);
  }

  return result;
};

const AddQueryValueDialog: React.FunctionComponent<{
  category?: Category | null;
  filter: any;
  onAdd: any;
  parsedQuery: any;
}> = ({ category, filter, onAdd, parsedQuery }) => {
  const [open, setOpen] = useState(false);
  const [filterQuery, setFilterQuery] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAdd = (key: string, value: any) => onAdd(key, value);

  const items = filter?.map((item: any) => ({
    ...item,
    values: fuzzyFilter(filterQuery, item.values, fuzzyOptions) || [],
  }));

  return (
    <>
      <Fab color="primary" size="small" onClick={handleOpen}>
        <Add />
      </Fab>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Добавить фильтр к "{category?.name}"</DialogTitle>

        <DialogContent>
          <Box mt={2}>
            <TextField
              fullWidth
              label="Поиск"
              value={filterQuery}
              onChange={e => setFilterQuery(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!filterQuery}
                      onClick={() => setFilterQuery('')}
                    >
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {items?.map((item: any) => (
            <Box key={item.key} mt={2}>
              <Box>
                <Typography variant="h5">{item.title}</Typography>
              </Box>
              <List>
                {item.values?.map((value: any) => (
                  <ListItem
                    selected={parsedQuery[item.key]?.includes(
                      String(value.value),
                    )}
                    key={value.value}
                    divider
                    onClick={() => handleAdd(item.key, value.value)}
                    button
                  >
                    <ListItemText primary={value.name} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        onClick={() => handleAdd(item.key, value.value)}
                        checked={parsedQuery[item.key]?.includes(
                          String(value.value),
                        )}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const EditCategoryDialog: React.FunctionComponent<{
  parent?: Category;
  category?: Category;
  afterCreate: () => any;
}> = ({ afterCreate, parent, category: providedCategory }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [query, setQuery] = useState<null | string>(null);

  const categoryRequest = useGetCategory({
    variables: { id: providedCategory?.id as number },
    skip: !providedCategory || !open,
  });

  const category = categoryRequest.data;

  const queryFilters = useQueryToFilters({
    query: category?.query,
    skip: !open,
  });

  useEffect(() => {
    if (category) {
      setName(category.name || '');
      setQuery(category.query || null);
      setDescription(category.description || '');
    }
  }, [category, open]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const reset = () => {
    setName('');
    setQuery(null);
    setLoading(false);
  };

  const handleSave = () => {
    category ? handleChange() : hanldeCreate();
  };

  const handleChange = () => {
    if (!category?.id) return null;
    const nextCategory: {
      name?: string;
      query?: string | null;
      description?: string | null;
    } = {};

    if (category?.name !== name && name) {
      nextCategory.name = name;
    }

    if (category?.query !== query) {
      nextCategory.query = query || null;
    }

    if (category?.description !== description) {
      nextCategory.description = description || null;
    }

    setLoading(true);

    handleChangeRequest(nextCategory);
  };

  const handleChangeRequest = (nextCategory: any) => {
    if (!category?.id) return null;
    changeCategory(category.id, nextCategory).then(() => {
      reset();
      afterCreate();
      setOpen(false);
      categoryRequest.mutate();
    });
  };

  const hanldeCreate = () => {
    const nextCategory: { name: string; parent?: number } = { name };
    if (parent) {
      nextCategory.parent = parent.id;
    }

    setLoading(true);

    createCategory(nextCategory).then(() => {
      reset();
      afterCreate();
      setOpen(false);
    });
  };

  const handleDeleteQueryItem = (key: any, value: any) => {
    if (!category?.id) return null;
    const nextQuery = {
      ...queryFilters.parsedQuery,
      //@ts-ignore
      [key]: queryFilters.parsedQuery[key].filter(
        //@ts-ignore
        queryValue => queryValue !== String(value),
      ),
    };

    const nextQueryString = new URLSearchParams(
      Object.entries(nextQuery).reduce(
        (prev: any, curr: any) =>
          curr[1]?.length ? { ...prev, [curr[0]]: curr[1] } : prev,
        {},
      ),
    ).toString();

    changeCategory(category.id, { query: nextQueryString }).then(() => {
      categoryRequest.mutate();
      afterCreate();
    });
  };

  const handleAddQueryItem = (key: any, value: any) => {
    if (!category?.id) return null;

    //@ts-ignore
    if (queryFilters?.parsedQuery?.[key]?.includes(String(value))) {
      return handleDeleteQueryItem(key, value);
    }

    const nextQuery = {
      ...queryFilters.parsedQuery,
      //@ts-ignore
      [key]: Array.from(
        //@ts-ignore
        new Set(queryFilters.parsedQuery[key] || []).add(value),
      ),
    };

    const nextQueryString = new URLSearchParams(nextQuery).toString();

    changeCategory(category.id, { query: nextQueryString }).then(() => {
      categoryRequest.mutate();
      afterCreate();
    });
  };

  const disabled = loading;

  return (
    <>
      {parent && (
        <Fab color="primary" size="small" onClick={handleOpen}>
          <Add />
        </Fab>
      )}
      {!parent && !providedCategory && (
        <Button onClick={handleOpen} variant="contained" color="primary">
          Новая категория
        </Button>
      )}
      {providedCategory && (
        <Fab color="primary" size="small" onClick={handleOpen}>
          <Edit />
        </Fab>
      )}
      {open && (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          {category && <DialogTitle>Изменить "{category.name}"</DialogTitle>}
          {parent && (
            <DialogTitle>Добавить подкатегорию к "{parent.name}"</DialogTitle>
          )}
          <DialogContent>
            <Box mt={2}>
              <TextField
                label="Название"
                value={name}
                onChange={e => setName(e.target.value)}
                fullWidth
              />
            </Box>
            <Box mt={2}>
              <TextField
                disabled={Boolean(category?.children?.length)}
                value={query}
                label="Query"
                onChange={e => setQuery(e.target.value)}
                fullWidth
              />
            </Box>
            {Boolean(queryFilters.queryTypes?.length) && (
              <Box mt={2}>
                <Box display="flex" alignItems="center">
                  <Typography variant="h5">Типы товаров</Typography>
                  <Box ml={2}>
                    <AddQueryValueDialog
                      parsedQuery={queryFilters.parsedQuery}
                      onAdd={handleAddQueryItem}
                      category={category}
                      filter={[
                        {
                          key: 'types',
                          title: 'Тип товара',
                          values: queryFilters.types,
                        },
                      ]}
                    />
                  </Box>
                </Box>
                {queryFilters.queryTypes?.map((type: Type) => (
                  <CategoryFilterType
                    type={type}
                    key={type.value}
                    onDelete={handleDeleteQueryItem}
                  />
                ))}
              </Box>
            )}
            {Boolean(queryFilters.queryTypes?.length) && (
              <Box mt={2}>
                <Box display="flex" alignItems="center">
                  <Typography variant="h5">Аттрибуты</Typography>
                  <Box ml={2}>
                    <AddQueryValueDialog
                      parsedQuery={queryFilters.parsedQuery}
                      onAdd={handleAddQueryItem}
                      category={category}
                      filter={queryFilters.attributes}
                    />
                  </Box>
                </Box>
                {queryFilters.queryAttributes?.map((attribute: any) => (
                  <CategoryFilterAttribute
                    attribute={attribute}
                    key={attribute.value}
                    onDelete={handleDeleteQueryItem}
                  />
                ))}
              </Box>
            )}
            <Box mt={2}>
              <TextField
                label="Описание"
                value={description}
                onChange={e => setDescription(e.target.value)}
                fullWidth
                multiline
                minRows={3}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Отмена</Button>
            <Button
              variant="contained"
              color="primary"
              disabled={disabled}
              onClick={handleSave}
            >
              {category ? 'Сохранить' : 'Создать'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

interface Type {
  count: number;
  name: string;
  value: number;
}

interface TypesMap {
  [key: string]: Type;
}

const CategoryListItem: React.FunctionComponent<{
  category: Category;
  afterCreate: () => any;
  level: number;
}> = ({ category, afterCreate, level }) => {
  return (
    <ListItem divider>
      <ListItemText
        primary={
          <Link
            target="_blank"
            href={`${process.env.REACT_APP_PUBLIC_PORTAL_HOST}/categories/${category.id}`}
          >
            {category.name}
          </Link>
        }
        secondary={
          category.query && (
            <Box>
              <Box>
                <Link
                  target="_blank"
                  href={`${process.env.REACT_APP_PUBLIC_PORTAL_HOST}/products?${category.query}`}
                >
                  <span style={{ wordBreak: 'break-all' }}>
                    {category.query}
                  </span>
                </Link>
              </Box>
            </Box>
          )
        }
      />
      <ListItemSecondaryAction>
        <Box display="flex">
          {level < 3 && (
            <EditCategoryDialog afterCreate={afterCreate} parent={category} />
          )}
          <Box ml={2}>
            <EditCategoryDialog afterCreate={afterCreate} category={category} />
          </Box>
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const CategoriesList: React.FunctionComponent<{
  categories?: Category[];
  afterCreate: () => any;
  level: number;
  typesMap?: TypesMap | null;
}> = ({ categories, afterCreate, level, typesMap }) => {
  return (
    <Box>
      <List disablePadding>
        {categories?.map(category => (
          <Fragment key={category.id}>
            <CategoryListItem
              category={category}
              afterCreate={afterCreate}
              level={level}
            />
            <Box ml={4}>
              <CategoriesList
                categories={category.children}
                afterCreate={afterCreate}
                level={level + 1}
              />
            </Box>
          </Fragment>
        ))}
      </List>
    </Box>
  );
};

interface Props {}

export const CategoriesPage: React.FunctionComponent<Props> = ({}) => {
  const classes = useStyles({});

  const [loading, setLoading] = useState(false);
  const [loadingRefresh, setLoadingRefresh] = useState(false);

  const categoriesRequest = useGetCategories({
    variables: { withEmpty: true },
  });

  const handleRefreshTCategoriesTree = () => {
    setLoadingRefresh(true);
    categoriesRefresh().then(() => {
      setLoadingRefresh(false);
      categoriesRequest.mutate();
    });
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <EditCategoryDialog afterCreate={categoriesRequest.mutate} />
        <Box ml={2}>
          <Link
            target="_blank"
            href={routesMap.categoriesMissingProducts.getRoute()}
          >
            Товары без категорий
          </Link>
        </Box>
        <Box ml={2}>
          <WithLoading loading={loadingRefresh}>
            <Button
              variant="outlined"
              disabled={loadingRefresh}
              onClick={handleRefreshTCategoriesTree}
            >
              Обновить дерево
            </Button>
          </WithLoading>
        </Box>
      </Box>
      <Box mt={4}>
        <TypesWithoutCategories categories={categoriesRequest.data} />
      </Box>
      <Box mt={4} maxWidth="800px">
        <Paper>
          <Box p={2}>
            <CategoriesList
              categories={categoriesRequest.data}
              afterCreate={categoriesRequest.mutate}
              level={1}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};
