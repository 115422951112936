import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@mui/material';
import { useParams } from 'react-router-dom';

//entities
import { User } from 'src/entities/user';
import { useGetUser } from 'src/entities/user';

interface Props {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      muted: {
        color: theme.palette.grey[500],
      },
    }),
  { name: 'UserPage' },
);

export const UserPage: React.FunctionComponent<Props> = ({}) => {
  const classes = useStyles({});

  const { userId } = useParams<{ userId: string }>();

  const userRequest = useGetUser({ variables: { id: userId as string } });



  return (
    <User
      user={userRequest.data?.data}
      onChanage={userRequest.refetch}
    />
  );
};
